







































import {Component, Prop, Vue} from "vue-property-decorator";
import EmployeesHRDocumentPayload from "@/dto/archive/EmployeesHRDocumentsPayload";
import DocumentUtils from "@/utils/DocumentUtils";
import EmployeeService from "@/services/EmployeeService";
import { processError } from "@/utils/ComponentUtils";
import { namespace } from "vuex-class";
import AddEmployeeDocumentModal from "@/components/payroll/business/employments/employee/AddEmployeeDocumentModal.vue";
import {EmploymentVue} from "@/components/payroll/business/employments/EmploymentVue";
import {IEmployment} from "@/dto/payroll/IEmployment";
import EmployeeDto from "@/dto/payroll/EmployeeDto";
import DeleteConfirmation from "@/components/payroll/business/DeleteConfirmation.vue";
import {EmployeesHRDocumentType} from "@/constants/DocumentConstants";
import PayrollFileDTO from "@/dto/payroll/PayrollFileDTO";
import PayrollService from "@/services/payroll/PayrollService";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";

const AppModule = namespace("App");

@Component<EmploymentDocuments>({
	computed: {
		DocumentUtils() {
			return DocumentUtils;
		}
	}
})
export default class EmploymentDocuments extends EmploymentVue<EmployeeDto> {

	@AppModule.Action
	private startLoading!: () => void

	@AppModule.Action
	private stopLoading!: () => void

	@Prop()
	employment!: EmployeeDto

	private employmentDocuments: Array<EmployeesHRDocumentPayload> = [];

	mounted(): void {
		this.loadDocuments();
	}

	loadDocuments(): void {
		this.startLoading();
		EmployeeService.getHRDocuments(this.employment.id!.toString()).then(
			response => {
				this.employmentDocuments = response.data;
				this.stopLoading();
			},
			error => {
				processError(error, this);
				this.stopLoading();
			}
		);
	}

	showModal(): void {
		this.$modal.show(
			AddEmployeeDocumentModal,
			{ onSuccess: this.loadDocuments }
		);
	}

  askDelete(id: number, type: EmployeesHRDocumentType, date: Date){
    this.$modal.show(
        DeleteConfirmation,
        {
          onDelete: () => this.deleteDocument(id),
          targetName: this.$t(`documents.type.${type}`) + " from " + this.$dateUtils.formatDateToAmericanFormat(date)
        }
    )
  }
  deleteDocument(id: number) {
    this.startLoading();
    EmployeeService.deleteHRDocument(id).then(
        () => {
          this.stopLoading();
          this.loadDocuments();
        },
        error => {
          processError(error, this);
          this.stopLoading();
        }
    );
  }

}

